import axiosInstance from "@/api/apiConfig";

export const uploadCSVRequest = async (formData) => {
  try {
    return await axiosInstance.post("sms/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  } catch (e) {
    throw { success: false, message: e.message || "System Error" };
  }
};
export const getSmsListRequest = async (params) => {
  try {
    const {
      data = [],
      allItems = 0,
      allPages: allPage = 0,
      perPage = 10,
      page = 0,
    } = await axiosInstance.get("sms/list", {
      params,
    });
    return {
      pagination: {
        allItems,
        allPage,
        perPage,
        page,
      },
      data,
    };
  } catch (e) {
    throw { success: false, message: e.message || "System Error" };
  }
};

export const cancelSmsRequest = async (ids) => {
  try {
    const { message, success } = await axiosInstance.post("sms/cancel", { ids });
    return { success, message };
  } catch (e) {
    throw { success: false, message: e.message || "System Error" };
  }
};
export const cancelAllSmsRequest = async () => {
  try {
    const { message, success } = await axiosInstance.post("sms/cancel-all");
    return { success, message };
  } catch (e) {
    throw { success: false, message: e.message || "System Error" };
  }
};
