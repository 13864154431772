<template>
  <Card class="mx-auto w-11">
    <template v-slot:header>
      <div class="px-3 pt-3 flex align-items-center">
        <h3 class="my-0 mr-auto">Scheduled SMS list</h3>
        <Btn
          class="bg-yellow-500 border-yellow-500 mr-4"
          :label="cancelSmsBtnLabel"
          :disabled="!selection.length"
          :loading="isLoading"
          @click="cancelSms" />
        <Btn
          class="bg-red-500 border-red-500"
          label="Cancel all SMS"
          :loading="isLoading"
          @click="cancelAllSms" />
      </div>
    </template>
    <template v-slot:content>
      <BasicTable
        class="sms-table"
        v-model:selection="selection"
        :columns="columns"
        dataKey="id"
        :value="smsList"
        responsiveLayout="scroll"
        :rowHover="true"
        :lazy="true"
        :alwaysShowPaginator="false"
        :loading="isLoading"
        :perPage="pagination.perPage"
        :allItems="pagination.allItems"
        :page="pagination.page"
        :rowClass="rowClass"
        :selectAll="selectAll"
        @selectAllChange="selectAllChange"
        @page="updatePagination">
        <Column selectionMode="multiple" headerStyle="width: 3em" />
      </BasicTable>
    </template>
  </Card>
</template>

<script setup>
import Card from "primevue/card";
import Btn from "primevue/button";
import Column from "primevue/column";
import { useToast } from "primevue/usetoast";
import { computed, onBeforeMount, reactive, ref } from "vue";
import BasicTable from "@/components/elements/BasicTable.vue";
import { cancelAllSmsRequest, cancelSmsRequest, getSmsListRequest } from "@/api/scheduleSmsService";
import { useUserStore } from "@/store";

const $userStore = useUserStore();
const $toast = useToast();

const PENDING_STATUS = "pending";

let isLoading = ref(false);
let selection = ref([]);
let smsList = ref([]);

let pagination = reactive({
  perPage: 10,
  allItems: 10,
  page: 1,
  allPage: 0,
});

const columns = [
  {
    header: "Phone number",
    field: "phoneNumber",
  },
  {
    header: "Message",
    field: "message",
  },
  {
    header: "Status",
    field: "status",
  },
  {
    header: "Send at",
    field: "sendAt",
  },
];

onBeforeMount(() => {
  getSmsList();
});
$userStore.$subscribe(async (mutation, state) => {
  if (!state.isSmsListUpdated) {
    await getSmsList();
    $userStore.isSmsListUpdated = true;
  }
});

const cancelSmsBtnLabel = computed(() => {
  return `Cancel SMS${selection.value.length ? ` (${selection.value.length})` : ""}`;
});

let selectAll = computed(() => {
  const selectionsBulkIds = new Set(selection.value.map(({ bulkId }) => bulkId));
  const bulkIdsPendingItems = smsList.value
    .filter(({ status }) => status === PENDING_STATUS)
    .map(({ bulkId }) => bulkId);

  return (
    !!bulkIdsPendingItems.length &&
    bulkIdsPendingItems.every((bulkId) => selectionsBulkIds.has(bulkId))
  );
});
const getSmsList = async () => {
  try {
    isLoading.value = true;
    const { data, pagination: updatedPagination } = await getSmsListRequest({
      page: pagination.page,
      perPage: pagination.perPage,
    });
    pagination.page = +updatedPagination.page;
    pagination.perPage = +updatedPagination.perPage;
    pagination.allItems = +updatedPagination.allItems;
    pagination.allPage = +updatedPagination.allPage;
    smsList.value = data;
  } catch (e) {
    console.warn("getSmsList error", e);
  } finally {
    isLoading.value = false;
  }
};
const updatePagination = ({ page, rows }) => {
  if (pagination.perPage !== rows) {
    pagination.perPage = rows;
    getSmsList();
    return;
  }
  if (pagination.page !== page + 1) {
    pagination.page = page + 1;
    getSmsList();
  }
};
const rowClass = ({ status }) => {
  // Return a CSS class based on the status of the item
  return status === PENDING_STATUS ? "" : "disable-selection";
};
const selectAllChange = (checked) => {
  selection.value = checked ? smsList.value.filter(({ status }) => status === PENDING_STATUS) : [];
};
const cancelSms = async () => {
  let ids = selection.value.map(({ bulkId }) => bulkId);
  try {
    isLoading.value = true;
    await cancelSmsRequest(ids);
    $toast.add({
      severity: "info",
      summary: "SMS items were canceled",
      life: 4000,
    });
    await getSmsList();
  } catch (e) {
    console.warn("getSmsList error", e);
  } finally {
    isLoading.value = false;
  }
};
const cancelAllSms = async () => {
  try {
    isLoading.value = true;
    await cancelAllSmsRequest();
    $toast.add({
      severity: "warn",
      summary: "All SMS items were canceled",
      life: 4000,
    });
    await getSmsList();
  } catch (e) {
    console.warn("getSmsList error", e);
  } finally {
    isLoading.value = false;
  }
};
</script>
<style lang="scss">
.sms-table {
  .disable-selection {
    .p-checkbox {
      display: none;
    }
  }
}
</style>
