<template>
  <Card class="mx-auto w-11">
    <template v-slot:header>
      <div class="px-3 pt-3 flex align-items-center">
        <h3 class="my-0">Schedule SMS send</h3>
        <div class="ml-auto flex align-items-center">
          <h4 class="my-0 mr-2 text-primary">Send out on</h4>
          <Calendar
            id="selectSendTime"
            v-model="sendTime"
            :minDate="new Date()"
            showTime
            dateFormat="mm/dd/yy"
            hourFormat="24" />
        </div>
      </div>
    </template>
    <template v-slot:content>
      <FileUpload
        name="fileCSVUpload"
        :maxFileSize="1000000"
        :showCancelButton="false"
        :preview-width="0"
        accept="text/csv"
        :customUpload="true"
        :multiple="true"
        choose-label="Choose CSV"
        @uploader="uploadFile">
        <template #empty>
          <p>Drag and drop files to here to upload.</p>
        </template>
      </FileUpload>
    </template>
  </Card>
</template>

<script setup>
import Card from "primevue/card";
import FileUpload from "primevue/fileupload";
import Calendar from "primevue/calendar";
import { useToast } from "primevue/usetoast";
import { ref } from "vue";
import { dateToUnixTimestamp } from "@/utils";
import { uploadCSVRequest } from "@/api/scheduleSmsService";
import { useUserStore } from "@/store";

const $userStore = useUserStore();
const $toast = useToast();

let isLoading = ref(false);
let sendTime = ref(null);

const uploadFile = async (event) => {
  isLoading.value = true;
  $toast.add({
    severity: "info",
    summary: "Uploading file started",
    detail: "Please wait till the upload finish",
    life: 5000,
  });
  const { success, message } = await uploadCSVRequest({
    files: event,
    time: dateToUnixTimestamp(sendTime.value),
  });
  isLoading.value = false;
  if (success) {
    $toast.add({ severity: "info", summary: "Success", detail: message, life: 10000 });
    $userStore.isSmsListUpdated = false;
    return;
  }
  $toast.add({ severity: "error", summary: "Error", detail: message, life: 10000 });
};
</script>
